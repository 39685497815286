.Home .lander {
  text-align: center;
  padding-bottom: 100px;
}

.Home .lander h1 {
  font-weight: 300;
  font-size: 50px;
}


img {
  max-height: 100%;
  max-width: 30vw;
  width: auto;
  margin: auto;
}
